import { h } from 'preact'
import { usePublicProfiles } from 'lib/membershipAppStateHooks'
import { useChatChannel } from 'lib/chatHooks'
import { ChatListMember } from './ChatListMember'
import './index.sass'
import EndUserDMAvatar from 'components/EndUserDMAvatar'


export function DMChatListMember({
  isSelected, forwarding, onToggleSelection, organization, memberUserDids, myPublicProfile, ...props
}) {
  const otherUserDids = memberUserDids.filter(did => did !== myPublicProfile.did)
  const { publicProfiles } = usePublicProfiles(otherUserDids, 'OrganizationChatPage')


  const {
    chatChannel,
  } = useChatChannel(props.uid, null, 'OrganizationChatPage')

  const isDirectChat = chatChannel.memberUserDids?.length === 2 && !chatChannel.isGroupChat
  const otherMemberProfile = publicProfiles.find(profile => myPublicProfile.did !== profile.did)
  const chatName = otherMemberProfile?.displayName || otherMemberProfile?.username
  const publicProfileNames = publicProfiles.map(profile => profile.displayName || profile.username)


  const chatImage = chatChannel.imageUrl
  return <ChatListMember {...{
    ...props,
    organization,
    myPublicProfile,
    isSelected,
    forwarding,
    onToggleSelection,
    name: isDirectChat ? chatName : chatChannel.name || publicProfileNames.join(', '),
    avatar: <EndUserDMAvatar publicProfiles={publicProfiles} chatImage={chatImage} size="sm"  />,
    uid: chatChannel.uid,
  }} />
}
