import logger from 'lib/logger'
import api from '../api'
import { loadEntity } from 'lib/actionHelpers'
import { setLocation } from 'resources/location'

import {
  isOrganizationForumFeedPost,
} from 'lib/feedPostHelpers'
import { createPageAlert } from '../resources/pageAlerts'
// homepage:feed
// user:${username}
// organization:${organizationApikey}:feed
// organization:${organizationApikey}:channel
export function loadPosts({ feed, before, pageSize }) {
  let username, organizationApikey, published, order
  if (feed === 'homepage:feed'){
    published = true
    order = 'new'
  } else if (/^user:([^:]+)$/.test(feed)){
    username = RegExp.$1
  } else if (/^organization:([^:]+):(feed|channel):(new|hot|best|top)$/.test(feed)){
    organizationApikey = RegExp.$1
    published = RegExp.$2 === 'feed'
    order = RegExp.$3
  }else{
    throw new Error(`invalid feed "${feed}"`)
  }

  const postUidsKey     = `${feed}:postUids`
  const loadingKey      = `${feed}:loading`
  const loadingErrorKey = `${feed}:loading:error`
  const fullyLoadedKey  = `${feed}:fullyLoaded`

  const {
    [loadingKey]: loading,
    [fullyLoadedKey]: fullyLoaded,
  } = this.getState()
  if (loading || (before && fullyLoaded)) return

  this.setState({
    [loadingKey]: true,
    [loadingErrorKey]: undefined,
  })

  api.getFeedPosts({ username, organizationApikey, published, order, before, pageSize }).then(
    ({ posts, lastPage, publicProfiles }) => {
      this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
      let newPostUids = posts.map(post => post.uid)
      if (before){
        const currentPostUids = this.getState()[postUidsKey] || []
        // TODO splice in new uids using specific before value?
        newPostUids = [...currentPostUids, ...newPostUids]
      }
      const newState = {
        [loadingKey]: undefined,
        [postUidsKey]: newPostUids,
        [fullyLoadedKey]: !!lastPage,
      }
      posts.forEach(post => {
        newState[`organizationFeedPost:${post.uid}`] = post
      })
      this.setState(newState)
    },
    error => {
      this.setState({
        [loadingKey]: undefined,
        [loadingErrorKey]: error,
      })
      if (!before){
        this.setState({
          [postUidsKey]: undefined,
        })
      }
    }
  )
}

export async function createPost({ post }) {
  let keyPrefix
  if (post.feedOrganizationApikey){
    keyPrefix = `organization:${post.feedOrganizationApikey}`
  }else if (post.feedUserDid){
    keyPrefix = `publicProfile:${post.feedUserDid}`
  }else{
    console.error('invalid post', post)
    throw new Error('invalid post')
  }
  const creatingKey = `${keyPrefix}:creatingFeedPost`
  const errorKey = `${keyPrefix}:creatingFeedPost:error`
  const createdKey = `${keyPrefix}:createdFeedPost`

  if (this.getState()[creatingKey]) return

  this.setState({
    [creatingKey]: true,
    [errorKey]: undefined,
    [createdKey]: undefined,
  })

  try{
    ({ post } = await api.createFeedPost({ post }))

    this.setState({
      [`organizationFeedPost:${post.uid}`]: post,
      [createdKey]: post.uid,
    })
  }catch(error){
    logger.error(`failed to post to ${keyPrefix}`, error)
    this.setState({[errorKey]: error})
    throw error
  }finally{
    this.setState({[creatingKey]: undefined})
  }
}

export async function updatePost({ post, changes }) {
  const postKey = `organizationFeedPost:${post.uid}`
  const updatingKey = `${postKey}:updating`
  const updatingErrorKey = `${updatingKey}:error`
  if (this.getState()[updatingKey]) return
  this.setState({
    [updatingKey]: true,
    [updatingErrorKey]: undefined,
  })
  try{
    const response = await api.updateFeedPost({
      feedPostUid: post.uid, changes
    })
    this.setState({ [postKey]: response.post })
  }catch(error){
    logger.error(`failed to update organization feed post`, error)
    this.setState({ [updatingErrorKey]: error })
  }finally{
    this.setState({ [updatingKey]: undefined })
  }
}

export async function repost({
  post,
  posterOrganizationApikey,
  feedOrganizationApikey,
  visibleTo,
}){
  const repostingKey = `feedPosts:reposting:${post.uid}`
  const errorKey = `${repostingKey}:error`
  const createdKey = `${repostingKey}:createdFeedPost`

  if (this.getState()[repostingKey]) return

  this.setState({
    [repostingKey]: true,
    [errorKey]: undefined,
    [createdKey]: undefined,
  })

  try{
    const { post: newPost } = await api.repostFeedPost({
      feedPostUid: post.uid,
      posterOrganizationApikey,
      feedOrganizationApikey,
      visibleTo,
      maxVisibleTo: post.maxVisibleTo,
    })

    this.setState({
      [`organizationFeedPost:${newPost.uid}`]: newPost,
      [createdKey]: newPost.uid,
    })
  }catch(error){
    this.setState({[errorKey]: error})
    throw error
  }finally{
    this.setState({[repostingKey]: undefined})
  }
}

export function publishOrganizationForumFeedPost({ post, visibleTo }){
  if (!post) throw new Error('post is required')
  if (!isOrganizationForumFeedPost(post))
    throw new Error('post must be an organization forum feed post')
  const postKey = `organizationFeedPost:${post.uid}`
  const publishingKey = `${postKey}:publishing`
  const publishingErrorKey = `${publishingKey}:error`
  if (this.getState()[publishingKey])
    return console.warn('refusing to publish twice', post)
  this.setState({
    [publishingKey]: true,
    [publishingErrorKey]: undefined,
  })
  return api.publishFeedPost({
    feedPostUid: post.uid,
    visibleTo,
  }).then(
    ({ post }) => {
      this.setState({
        [`organizationFeedPost:${post.uid}`]: post,
        [publishingKey]: undefined,
      })
      setLocation(`/${post.feedOrganizationApikey}/published?p=${post.uid}`)
    },
    error => {
      logger.error(`failed to publish organization feed post`, error)
      this.setState({
        [publishingKey]: undefined,
        [publishingErrorKey]: error,
      })
      return error
    }
  )
}

export function getFeedPostsDeletingReasons(){
  const deletingReasonsKey = 'organizationFeedPosts:deletingReasons'
  const loadingKey = `${deletingReasonsKey}:loading`
  const loadingErrorKey = `${deletingReasonsKey}:loading:error`

  if (this.getState()[loadingKey]) return

  this.setState({
    [loadingKey]: true,
    [loadingErrorKey]: undefined,
  })

  api.getFeedPostsDeletingReasons().then(
    ({ reasons }) => {
      this.setState({
        [deletingReasonsKey]: reasons,
        [loadingKey]: undefined,
      })
    },
    error => {
      this.setState({
        [loadingKey]: undefined,
        [loadingErrorKey]: error,
      })
    }
  )
}

export function deletePost({ post, comment, reason, sendNotification }) {
  const postKey = `organizationFeedPost:${post.uid}`
  const deletingKey = `${postKey}:deleting`
  const deletingErrorKey = `${postKey}:deleting:error`

  if (this.getState()[deletingKey]) return

  this.setState({
    [deletingKey]: true,
    [deletingErrorKey]: undefined,
  })

  api.deleteFeedPost({ feedPostUid: post.uid, reason, comment, sendNotification }).then(
    () => {
      this.setState({
        [deletingKey]: undefined,
        [postKey]: undefined,
      })
      removePostFromChannelFeeds.call(this, post)
    },
    error => {
      this.setState({
        [deletingKey]: undefined,
        [deletingErrorKey]: error,
      })
    },
  )
}

export function loadPost(feedPostUid){
  return loadEntity.call(this, {
    reload: false,
    entityKey: `organizationFeedPost:${feedPostUid}`,
    request: () =>
      api.getFeedPost({ feedPostUid })
        .then(({ post, publicProfiles }) => {
          if (publicProfiles)
            this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
          return post
        })
    ,
  })
}

export function loadProvenance(feedPostUid){
  return loadEntity.call(this, {
    reload: false,
    entityKey: `organizationFeedPost:${feedPostUid}:provenanceChain`,
    request: () =>
      api.getFeedPostProvenance({ feedPostUid })
        .then(({ provenance }) => provenance)
    ,
  })
}

export function loadComments({feedPostUid, before}){
  const commentsKey = `organizationFeedPost:${feedPostUid}:comments`

  const { loading, fullyLoaded } = this.getState()[commentsKey] || {}
  if (loading || (before && fullyLoaded)) return

  this.extendObject(commentsKey, {
    loading: true,
    loadingError: undefined,
  })

  api.getFeedPostComments({ feedPostUid, before }).then(
    ({ comments, lastPage, publicProfiles }) => {
      if (!comments){
        this.setState({
          [commentsKey]: { notFound: true },
        })
        return
      }
      if (publicProfiles)
        this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
      const commentsByUid = {}
      comments.forEach(comment => {
        commentsByUid[`${comment.uid}`] = comment
      })
      this.extendObject(commentsKey, {
        loading: undefined,
        fullyLoaded: !!lastPage,
        ...commentsByUid,
      })
    },
    loadingError => {
      this.extendObject(commentsKey, {
        loading: undefined,
        loadingError,
      })
    }
  )
}

export function createComment({feedPostUid, comment}){
  if (!feedPostUid) throw new Error('feedPostUid is required')
  const commentsKey = `organizationFeedPost:${feedPostUid}:comments`
  const creatingKey = `organizationFeedPostComment:${feedPostUid}:creating`
  const creatingErrorKey = `organizationFeedPostComment:${feedPostUid}:creating:error`

  if (this.getState()[creatingKey]) return

  this.setState({
    [creatingKey]: true,
    [creatingErrorKey]: undefined,
  })
  comment = { comment: comment.body }
  return api.createFeedPostComment({ feedPostUid, comment }).then(
    ({ comment }) => {
      const feedPostKey = `organizationFeedPost:${feedPostUid}`
      this.extendObject(commentsKey, {
        [comment.uid]: comment,
      })
      this.setState({
        [creatingKey]: undefined,
      })
      const feedPost = this.getState()[feedPostKey]
      if (feedPost){
        this.setState({
          [feedPostKey]: {
            ...feedPost,
            commentCount: (feedPost.commentCount || 0) + 1,
          },
        })
      }
    },
    creatingError => {
      logger.error(`failed to create organization feed post comment`, creatingError)
      this.setState({
        [creatingKey]: undefined,
        [creatingErrorKey]: creatingError,
      })
      return creatingError
    }
  )
}

export function deleteComment({comment}){
  const deletingKey = `organizationFeedPostComment:${comment.uid}:deleting`
  const deletingErrorKey = `organizationFeedPostComment:${comment.uid}:deleting:error`

  if (this.getState()[deletingKey]) return

  this.setState({
    [deletingKey]: true,
    [deletingErrorKey]: undefined,
  })
  const removeCommentFromAppState = () => {
    const commentsKey = `organizationFeedPost:${comment.feedPostUid}:comments`
    this.extendObject(commentsKey, {
      [comment.uid]: undefined,
    })
    this.setState({
      [deletingKey]: undefined,
    })
  }
  return api.deleteFeedPostComment({ commentUid: comment.uid }).then(
    () => {
      removeCommentFromAppState(comment)
    },
    deletingError => {
      if (`${deletingError}`.includes('comment already deleted')){
        return removeCommentFromAppState(comment)
      }
      logger.error(`failed to delete organization feed post comment`, deletingError)
      this.setState({
        [deletingKey]: undefined,
        [deletingErrorKey]: deletingError,
      })
      return deletingError
    }
  )
}

export function voteOnPost({ feedPostUid, vote }){
  if (!feedPostUid) throw new Error('feedPostUid is required')
  const feedPostKey = `organizationFeedPost:${feedPostUid}`
  const votingKey = `${feedPostKey}:voting`
  const votingErrorKey = `${feedPostKey}:voting:error`

  if (this.getState()[votingKey]) return

  this.setState({
    [votingKey]: {feedPostUid, vote},
  })

  return api.voteOnFeedPost({ feedPostUid, vote }).then(
    () => {
      const feedPost = this.getState()[feedPostKey]
      if (feedPost && feedPost.myVote !== vote){
        let {upvoteCount = 0, downvoteCount = 0} = feedPost
        if (vote === 1) upvoteCount++
        if (vote === -1) downvoteCount++
        if (feedPost.myVote === 1) upvoteCount--
        if (feedPost.myVote === -1) downvoteCount--
        this.setState({
          [feedPostKey]: {
            ...feedPost,
            myVote: vote,
            upvoteCount,
            downvoteCount,
          }
        })
      }
      this.setState({
        [votingKey]: undefined,
      })
    },
    votingError => {
      logger.error(`failed to vote on organization feed post`, votingError)
      this.setState({
        [votingKey]: undefined,
        [votingErrorKey]: votingError,
      })
      return votingError
    }
  )
}

export function hideFromMe({ post }){
  const postKey = `organizationFeedPost:${post.uid}`
  const hidingFromMeKey = `${postKey}:hidingFromMe`
  const hidingFromMeErrorKey = `${postKey}:hidingFromMe:error`

  if (this.getState()[hidingFromMeKey]) return

  this.setState({
    [hidingFromMeKey]: true,
    [hidingFromMeErrorKey]: undefined,
  })

  api.hideFeedPostFromMe({ feedPostUid: post.uid }).then(
    () => {
      this.setState({
        [hidingFromMeKey]: undefined,
        [postKey]: undefined,
      })
      removePostFromChannelFeeds.call(this, post)
    },
    error => {
      this.setState({
        [hidingFromMeKey]: undefined,
        [hidingFromMeErrorKey]: error,
      })
    },
  )
}

export async function hide({ post }) {
  const postKey = `organizationFeedPost:${post.uid}`
  const hidingKey = `${postKey}:hiding`
  const hidingErrorKey = `${postKey}:hiding:error`

  if (this.getState()[hidingKey]) return

  this.setState({
    [hidingKey]: true,
    [hidingErrorKey]: undefined,
  })

  try {
    await api.hideFeedPost({ feedPostUid: post.uid })
    this.setState({ [postKey]: undefined })
    removePostFromChannelFeeds.call(this, post)
  } catch(error) {
    this.setState({ [hidingErrorKey]: error })
  } finally {
    this.setState({ [hidingKey]: undefined })
  }
}

function removePostFromChannelFeeds(post){
  const feed = `${post.feedOrganizationApikey}:${ post.published ? 'feed' : 'channel'}`
  const appState = this.getState()
  const newState = {}
  for (const order of 'new hot best top'.split(' ')){
    const appStateKey = `${feed}:${order}`
    const postUids = appState[appStateKey]
    if (!postUids) continue
    newState[appStateKey] = postUids.filter(postUid => postUid !== post.uid)
  }
  this.setState(newState)
}


export async function getUrlPreview(url){
  try{
    const response = await api.getUrlPreview(url)
    return response.preview
  }catch(error){
    if (`${error}`.includes('Invalid URL')) return { url, invalidUrl: true }
    throw error
  }
}

export function loadPinnedPosts({ organizationApikey }){
  const pinnedPostsKey = 'organizationFeedPosts:pinned'
  const loadingKey = `organizationFeedPosts:pinned:loading`
  const loadingErrorKey = `organizationFeedPosts:pinned:loading:error`

  if (this.getState()[loadingKey]) return

  this.setState({
    [loadingKey]: true,
    [loadingErrorKey]: undefined,
  })

  api.getPinnedPostsForOrganization({ organizationApikey })
    .then(response => {
      this.setState({
        [pinnedPostsKey]: response.pinnedPosts.pinnedPosts.pinnedPosts,
        [loadingKey]: undefined,
      })
    })
    .catch(error => {
      this.setState({
        [loadingKey]: undefined,
        [loadingErrorKey]: error,
      })
    })
}

export function pinPost({ post }){
  const postKey = `organizationFeedPost:${post.uid}`
  const pinningKey = `${postKey}:pinning`
  const pinningErrorKey = `${postKey}:pinning:error`

  if (this.getState()[pinningKey]) return

  this.setState({
    [pinningKey]: true,
    [pinningErrorKey]: undefined,
  })

  api.pinFeedPost({ feedPostUid: post.uid }).then(
    () => {
      this.setState({
        [pinningKey]: undefined,
        [postKey]: {
          ...post,
          pinned: true,
        },
      })
      const oldState = this.getState()
      const newState = {}
      newState[`organization:refreshPinned`] = Boolean(!oldState?.[`organization:refreshPinned`])
      this.setState(newState)
      //localStorage.setItem(postKey, true);
      // this.loadPinnedPosts({ organizationApikey });
      createPageAlert({
        type: 'success',
        message: 'Post pinned successfully!',
      })
    },
    error => {
      this.setState({
        [pinningKey]: undefined,
        [pinningErrorKey]: error,
      })
      // createPageAlert({
      //   type: 'success',
      //   message: 'Post pinned successfully!',
      // })
    },
  )
}


export function unpinPost({ post }){
  const postKey = `organizationFeedPost:${post.uid}`
  const unpinningKey = `${postKey}:unpinning`
  const unpinningErrorKey = `${postKey}:unpinning:error`

  if (this.getState()[unpinningKey]) return

  this.setState({
    [unpinningKey]: true,
    [unpinningErrorKey]: undefined,
  })

  api.unpinFeedPost({ feedPostUid: post.uid }).then(
    () => {
      this.setState({
        [unpinningKey]: undefined,
        [postKey]: {
          ...post,
          pinned: false,
        },
      })
      const oldState = this.getState()
      const newState = {}
      newState[`organization:refreshPinned`] = Boolean(!oldState?.[`organization:refreshPinned`])
      this.setState(newState)
      //localStorage.setItem(postKey, false);
      // this.loadPinnedPosts({ organizationApikey });
      createPageAlert({
        type: 'info',
        message: 'This post has been unpinned.',
      })
    },
    error => {
      this.setState({
        [unpinningKey]: undefined,
        [unpinningErrorKey]: error,
      })
    },
  )
}
